import accountWhiteBox from './AccountWhiteBox.vue';    
import alertMessageBox from './alertMessageBox.vue';    
import confirmMessageBox from './confirmMessageBox.vue';    
// 定义插件对象
const MessageBox = {};
// vue的install方法，用于定义vue插件
MessageBox.install = function (Vue, options) {
    //alert弹框
    const alertMessageBoxInstance = Vue.extend(alertMessageBox);
    let alertMessage;
    //确定弹框
    const confirmMessageBoxInstance = Vue.extend(confirmMessageBox);
    let confirmMessage;
    //黑名单弹框
    const accountWhiteBoxInstance = Vue.extend(accountWhiteBox);
    let accountWhiteMessage;
    
    // 初始化注入
    const initInstance = (name) => {
      // 实例化vue实例
      if(name=='alertMessage'){
        alertMessage = new alertMessageBoxInstance();
      }
      if(name=='confirmMessage'){
        confirmMessage = new confirmMessageBoxInstance();
      }
      if(name=='accountWhiteMessage'){
        accountWhiteMessage = new accountWhiteBoxInstance();
      }
    };
    //在Vue的原型上添加实例方法，以全局调用
    Vue.prototype.$msgBox = {
      alertMessageBox(options) {
        if (!alertMessage) {
          initInstance('alertMessage');
        }
        if (typeof options === 'object') {
          Object.assign(alertMessage, options);
          let a = alertMessage.$mount().$el;
          document.body.appendChild(a);
        }
        return new Promise((resolve, reject) =>{
          alertMessage.alertMessageBox()
            .then(val => {
              alertMessage = null;
              resolve(val);
            })
            .catch(err => {
              alertMessage = null;
              reject(err);
            });
        })
      },
      confirmMessageBox(options) {
        if (!confirmMessage) {
          initInstance('confirmMessage');
        }
        if (typeof options === 'object') {
          Object.assign(confirmMessage, options);
          let b = confirmMessage.$mount().$el;
          document.body.appendChild(b);
        }
        return new Promise((resolve, reject) =>{
          confirmMessage.confirmMessageBox()
          .then(val => {
            confirmMessage = null;
            resolve(val);
          })
          .catch(err => {
            confirmMessage = null;
            reject(err);
          });
        });
      },
      accountWhiteMessageBox(options) {
        if (!accountWhiteMessage) {
          initInstance('accountWhiteMessage');
        }
        if (typeof options === 'object') {
          Object.assign(accountWhiteMessage, options);
          let c = accountWhiteMessage.$mount().$el;
          document.body.appendChild(c);
        }
        return new Promise((resolve, reject) =>{
          accountWhiteMessage.accountWhiteMessageBox()
          .then(val => {
            accountWhiteMessage = null;
            resolve(val);
          })
          .catch(err => {
            accountWhiteMessage = null;
            reject(err);
          });
        });
      },
    };
};
export default MessageBox;