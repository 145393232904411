<template>
  <div class="alertMessageBox" v-show="isShowMessageBox">
    <div class="mask"></div>
    <div class="alertMessageContent" :style="{width}">
      <svg class="closeIcon" aria-hidden="true" @click="cancel()">
        <use xlink:href="#iconguanbi" />
      </svg>
      <h3 class="title">{{title}}</h3>
      <div class="content">{{content}}</div>
      <div class="foot">
        <div class="confirm margin-r10" v-if="cancelText" @click="confirm()">{{cancelText}}</div>
        <div class="confirm" @click="confirm(true)">{{btnText}}<span v-if="delayed">({{delayed}})</span></div>
      </div>
    </div>
  </div>
</template>
    
  
<script>
export default {
  props: {
    width: {
      type: String,
      default: "30%"
    },
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    delayed: {
      type: Number,
      default: 0
    },
    cancelText: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: '知道了'
    },
  },
  created() {
  },
  data() {
    return {
      isShowMessageBox: false,
      resolve: "",
      reject: "",
      promise: "" // 保存promise对象
    };
  },
  watch: {},
  methods: {
    // 确定,将promise断定为resolve状态
    confirm: function(row) {
      this.isShowMessageBox = false;
      if (row) {
        this.resolve(row);
      } else {
        this.resolve();
      }
      this.remove();
    },
    // 取消,将promise断定为reject状态
    cancel: function() {
      this.isShowMessageBox = false;
      this.reject("cancel");
      this.remove();
    },
    // 弹出messageBox,并创建promise对象
    alertMessageBox: function() {
      this.isShowMessageBox = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
        if(this.delayed){
          this.timer = setInterval(()=>{
            this.delayed--;
            if(this.delayed<0){
              this.confirm();
            }
          },1000)
        }
      });
      return this.promise;
    },
    remove: function() {
      setTimeout(() => {
        this.destroy();
      }, 300);
    },
    destroy: function() {
      this.timer && clearInterval(this.timer);
      this.$destroy();
      document.body.removeChild(this.$el);
    }
  }
};
</script>
<style lang="scss">
.alertMessageBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 999;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    background: rgba($color: #000000, $alpha: 0.7);
  }
  .alertMessageContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    min-height: 262px;
    border-radius: 10px;
    background: #fff;
    .closeIcon {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .title {
      margin: 30px 0;
      min-height: 31px;
      font-size: 24px;
      font-weight: bold;
      color: rgba(18, 18, 18, 1);
      text-align: center;
    }
    .content {
      padding: 0 60px;
      font-size: 18px;
      font-weight: bold;
      color: rgba(18, 18, 18, 1);
      text-align: center;
      word-break: break-all;
    }
    .foot{
      display: flex;
      padding: 0 100px;
      .confirm {
        margin: 40px auto;
        width: 331px;
        height: 50px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
}
</style>