let apiBase = {
    queryAllApk: '/api/identifyApk/queryAllApk',//获取banner    
}
  let ServiceSeverObject = null
  class ServiceSever {
    constructor() {}
    queryAllApk(params = {}) {
      return $http({
        method: 'post',
        url: apiBase.queryAllApk,
        data: params
      })
    }
  }
  
  function singleServiceSever() {
    if (ServiceSeverObject === null) {
      ServiceSeverObject = new ServiceSever()
    }
    return ServiceSeverObject
  }
  export default singleServiceSever()