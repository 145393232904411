import Vue from 'vue';
import Vuex from 'vuex';
import {singleMembersSever} from '@/common/api-config';
Vue.use(Vuex);
const store ={
  namespaced:true,//命名空间
  state:{ //状态对象：共享值的集合
    isAllBind:false,
    members:[]
  },
  mutations:{  //同步修改
    set(state, obj){//初始化
      for(let name in obj){
        state[name] = obj[name];
      }
    },
  },
  getters:{//监测状态
    isAllBind:(state)=>{
      return state.isAllBind
    },
    members:(state)=>{
      return state.members
    },
  },
  actions:{//异步修改
    updateMembers(context){ //context 上下文对象，同管全局
      return new Promise((resolve, reject)=>{
        return  singleMembersSever.memberList().then((res)=>{
          if(res.code==0 ){
            let mustFirmArr = res.data.filter(item => item.type!=2);//过滤需要绑定的厂商
            let filtersArr = res.data.filter(item => item.bindStatus==2&&item.type!=2);//过滤出绑定了的厂商
            let isAllBind = filtersArr.length==mustFirmArr.length;//是否全部绑定了厂商
            context.commit('set',{//上下文对象调用commit执行add方法
              isAllBind:isAllBind,
              members:res.data,
            }); 
            resolve(isAllBind);
          }else{
            resolve(false);
          }
        })
      })
    },
  }
}

export default store

