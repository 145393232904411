import Vue from 'vue';
import Vuex from 'vuex';
import {
  singleAccountSever
} from '@/common/api-config';
Vue.use(Vuex);
const store = {
  namespaced:true,//命名空间
  state: { //状态对象：共享值的集合
    accountInfo: {},
    userData: {},
  },
  mutations: { //同步修改
    setInfo(state, obj) {
      utils.isEmpty(obj)?Object.assign(state.accountInfo, obj):state.accountInfo={};
    },
    setData(state, obj) {
      utils.isEmpty(obj)?Object.assign(state.userData, obj):state.userData={};
    },
  },
  getters: { //监测状态
    accountInfo: (state) => {
      return state.accountInfo
    },
    userData: (state) => {
      return state.userData
    },
  },
  actions: { //异步修改
    updateAccountInfo(context) { //context 上下文对象，同管全局
      return new Promise((resolve, reject) => {
        singleAccountSever.currentInfo().then((res) => {
          if (res.code == 0 && window.utils.isEmpty(res.data)) {
            context.commit('setInfo', res.data); //上下文对象调用commit执行add方法
            resolve(res);
          } else {
            context.commit('setInfo', {}); //上下文对象调用commit执行add方法
            localStorage.clear();
            reject(res);
          }
        })
      })
    },
    updateUserData(context) { //context 上下文对象，同管全局
      return new Promise((resolve, reject) => {
        if (localStorage.getItem('Token')) {
          singleAccountSever.getData().then((res) => {
            if (res.code == 0) {
              context.commit('setData', res.data); //上下文对象调用commit执行add方法
              resolve(res);
            } else {
              reject(res);
            }
          })
        }
      })
    },

  }
}

export default store