const routes = [
  {
    path: 'account/login',
    name: 'account/login',
    component: () => import('@/views/account/Login.vue')
  },
  {
    path: 'account/register',
    name: 'account/register',
    component: () => import('@/views/account/register/Register.vue')
  },
  {
    path: 'account/registerSuccess',
    name: 'account/registerSuccess',
    component: () => import('@/views/account/register/RegisterSuccess.vue')
  },
  {
    path: 'account/password/reback',
    name: 'account/password/reback',
    component: () => import('@/views/account/password/RebackPassWord.vue')
  },
  {
    path: 'account/modify',
    name: 'account/modify',
    meta:{isLogin:true},//isLogin:是否需要登录 
    component: () => import('@/views/account/ModifyAccount.vue')
  },
  {
    path: 'account/password/change',
    name: 'account/password/change',
    meta:{isLogin:true},//isLogin:是否需要登录 
    component: () => import('@/views/account/password/ChangePassWord.vue')
  },
  {
    path: 'account/perfect/info',
    name: 'account/perfect/info',
    meta:{isLogin:true},//isLogin:是否需要登录 
    component: () => import('@/views/account/perfect/Info.vue')
  },
  {
    path: 'account/perfect/status',
    name: 'account/perfect/status',
    meta:{isLogin:true},//isLogin:是否需要登录 
    component: () => import('@/views/account/perfect/Status.vue')
  },
  {
    path: 'account/role/info',
    name: 'account/role/info',
    meta:{isLogin:true},//isLogin:是否需要登录 
    component: () => import('@/views/account/role/Info.vue')
  },
  // {
  //   path: 'account/role/government',
  //   name: 'account/role/government',
  //   meta:{isLogin:true},//isLogin:是否需要登录 
  //   component: () => import('@/views/account/role/Government.vue')
  // },
  // {
  //   path: 'account/role/personal',
  //   name: 'account/role/personal',
  //   meta:{isLogin:true},//isLogin:是否需要登录 
  //   component: () => import('@/views/account/role/Personal.vue')
  // },
]

export default routes;
