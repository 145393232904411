const routes = [
  {
    path: '/',
    // redirect: 'index',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: 'index',
    name: 'index',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: 'home/join',
    name: 'home/join',
    component: () => import('@/views/home/Join.vue')
  },
  {
    path: 'home/union',
    name: 'home/union',
    redirect:'home/union/service', 
  },
  {
    path: 'home/service',
    name: 'home/service',
    redirect:'home/union/service', 
  },
  {
    path: 'home/union/service',
    name: 'home//union/service',
    component: () => import('@/views/home/Service.vue')
  },
  {
    path: 'home/union/openness',
    name: 'home//union/openness',
    component: () => import('@/views/home/Openness.vue')
  },
  {
    path: 'home/about',
    name: 'home/about',
    component: () => import('@/views/home/About.vue')
  },
  {
    path: 'home/constitution',
    name: 'home/constitution',
    component: () => import('@/components/Constitution.vue')
  },
  {
    path: 'home/intellectualProperty',
    name: 'home/intellectualProperty',
    component: () => import('@/components/IntellectualProperty.vue')
  },
  {
    path: 'home/news',
    redirect: 'home/news/list'
  },
  {
    path: 'home/news/list',
    name: 'home/news/list',
    component: () => import('@/views/home/news/List.vue'),
    meta: {
      cacheTo: ["home/news/info"], //只有从本路由进入这个数组里的路由才会缓存list这个页面
      keepAlive: true,
    }
  },
  {
    path: 'home/news/info',
    name: 'home/news/info',
    component: () => import('@/views/home/news/Info.vue')
  },
]

export default routes;
