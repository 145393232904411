let apiBase = {
  accountBind: '/api/accountBind/bind',//厂商绑定
  memberList: '/api/accountBind/memberList',//厂商list
  memberOauthUrl: '/api/accountBind/memberOauthUrl',//厂商绑定跳转url
  memberUnbind: '/api/accountBind/unbind',//解除绑定
  

}
let MembersSeverObject = null
class MembersSever {
  constructor() {}
  accountBind(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.accountBind,
      data: params
    })
  }
  memberList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.memberList,
      data: params
    })
  }
  memberOauthUrl(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.memberOauthUrl,
      data: params
    })
  }
  memberUnbind(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.memberUnbind,
      data: params
    })
  }
}

function singleMembersSever() {
  if (MembersSeverObject === null) {
    MembersSeverObject = new MembersSever()
  }
  return MembersSeverObject
}
export default singleMembersSever()