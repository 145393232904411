<template>
    <header class="headBox">
        <div class="headMenu">
            <el-row>
                <el-col :xs="24" :span="6" >
                    <router-link class="logoBox cursor" tag="div" to="/">
                      <svg-icon svg-name="service-icon" class="ita-logo"></svg-icon>
                      <h1 class="ita-logo-text ellipsis">移动智能终端生态联盟</h1>
                    </router-link>
                </el-col>
                <el-col  v-if="tabsSwtich" :xs="24" :sm="{span:10,offset:8}">
                    <div class="navBox">
                      <ul class="nav">
                        <router-link class="item" to="/" exact >首页</router-link>
                        <div class="item ">
                          <div class="services item " :class="{'router-link-active':serverNavIsActive}" > 联盟服务</div>
                          <div class="subMenu">
                              <div class="item" @click="gotoService()" >金标认证</div>
                              <div class="item" @click="gotoOpenness()" >开放能力</div>
                          </div>
                        </div>
                        <router-link class="item" to="/home/join" exact>加入我们</router-link>
                        <router-link class="item" to="/home/about" exact>关于我们</router-link>
                        <template v-if="utils.isEmpty(accountInfo)">
                            <router-link v-if="utils.getTerminal()=='pc'" class="item" to="/console">控制台</router-link>
                            <li class="item">
                              <div class="accountBox">
                                  <div class="account">
                                      <div class="accountText ellipsis">{{accountInfo.email}}</div>
                                  </div>
                                  <div class="accountOption">
                                    <!-- dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过 -->
                                    <template v-if="accountInfo.dataState==2">
                                      <!-- type:1-企业 2-个人 3-政府单位 -->
                                      <router-link  class="item" to="/account/role/info" target="_blank">修改资料</router-link>
                                      <!-- <router-link v-else-if="accountInfo.type==2" class="item" to="/account/role/personal" target="_blank">修改资料</router-link>
                                      <router-link v-else-if="accountInfo.type==3" class="item" to="/account/role/government" target="_blank">修改资料</router-link> -->
                                    </template>
                                    <template v-else>
                                      <router-link class="item" to="/account/perfect/status" target="_blank">修改资料</router-link>
                                    </template>
                                    <router-link class="item" to="/account/modify" target="_blank">修改账户邮箱</router-link>
                                    <router-link class="item" to="/account/password/change" target="_blank">修改密码</router-link>
                                    <li class="item" @click="logout">退出登录</li>
                                  </div>
                              </div>
                            </li>
                        </template>
                        <template v-else>
                          <router-link class="item" to="/account/register">注册</router-link>
                          <router-link class="item" to="/account/login">登录</router-link>
                        </template>
                      </ul>
                    </div>
                </el-col>
            </el-row>
        </div>
    </header>
</template>

<script>
  import {singleAccountSever} from "@/common/api-config";
  export default {
    created(){
      this.$store.dispatch("accountStore/updateAccountInfo", {}).then((res) => {}).catch((res) => {});// 每次页面都去后台拿用户信息，更新本地状态
    },
    data() {
      return {
        tabsSwtich: true,//是否展示tabs导航开关
        serverNavIsActive: false,//联盟服务导航选中开关
      };
    },
    computed: {
      accountInfo() {
        return this.$store.getters["accountStore/accountInfo"];//账户状态info
      },
    },
    watch: {
      '$route' (to, from){
        this.tabsSwtich = to.path.indexOf('account')==-1;//账户模块不需要 tabs导航
        this.serverNavIsActive = to.path.indexOf('home/union')==1;// 判断联盟服务路径
      }
    },
    methods: {
      // 登出
      logout() {
        singleAccountSever.loginOut({
          token: this.accountInfo.token
        }).then(result => {
          if (result.code == 0) {
            localStorage.clear();
            this.$store.dispatch("accountStore/updateAccountInfo", {}).then(res => {//去后台拿账户信息，更新本地状态
            }).catch(()=>{});
            this.$router.push('/');
          }
        });
      },
      gotoService(){
        this.$router.push({path:"/home/union/service"})
      },
      gotoOpenness(){
        this.$router.push({path:"/home/union/openness"})
      }
    },
  };
</script>

<style scoped lang="scss">
   .headBox{
     /* 不要删 */
      h1, ul, ol { list-style:none; margin:0; padding:0;} 
      .ellipsis {
        vertical-align: middle;
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      box-sizing:border-box;
     /* 不要删 */
      position: fixed;
      top:0;
      z-index: 10;
      width: 100%;
      min-height: 80px;
      line-height: 80px;
      font-size: 16px;
      background: #121212;
      .headMenu{
        height: 100%;
        .logoBox {
            display: flex;
            align-items: center;
            padding-left: 35px;
            .ita-logo{
                width: 40px;
                height: 30px;
            }
            .ita-logo-text{
                color: #e9d59c;
                font-size: 18px;
                font-weight: bold;
                margin-left: 9px;
            }
        }
        .navBox {
          display: flex;
          justify-content: flex-end;
          .nav{
            flex: 1;
            display: flex;
            .item{
              position: relative;
              flex: auto;
              color: #666;
              text-align: center;
              transition:color .3s;
              cursor: pointer;
              .services{
                width: 100%;
                height: 100%;
                color: #666
              }
              .subMenu{
                position: absolute;
                z-index: -9999;
                top: -999px;
                left: 50%;
                width: 125px;
                background-color: #121212;
                transform: translateX(-50%);
                padding:0 0 6px 0;
                transition: 0.5s top;      
                .item{
                  height: 50px;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  a{
                    color: #666;
                  }
                }
                .item a:hover {
                  color: #f3eac2;
                }
              }
              &:hover .subMenu{
                top: 100%;
              }
              .accountBox {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  height: 100%;
                  font-size: 14px;
                  cursor: pointer;
                  .account {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end ;
                    width: 158px;
                    height:58px;
                    text-align: center;
                    color: #121212;
                    z-index: 2;
                    background: #121212;
                    .accountText {
                        position: relative;
                        padding:0 15%;
                        height: 34px;
                        border: 1px solid #f3eac2;
                        border-radius: 16px;
                        background-color: #000;
                        line-height: 32px;
                        color: #f3eac2;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 40%;
                            right: 10px;
                            width: 8px;
                            height: 8px;
                            border-bottom: 1px solid #f3eac2;
                            border-left: 1px solid #f3eac2;
                            transform:rotate(-45deg)  translateY(-50%);
                        }
                    }
                  }
                  .accountOption {
                      position: absolute;
                      top: -999px;
                      left: 50%;
                      display: flex;
                      flex-wrap: wrap;
                      width: 158px;
                      border-top: 4px solid #C9C199;
                      box-shadow:0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                      border-radius:0px 0px 10px 10px;
                      color: #666666;
                      line-height: 50px;
                      background-color: #fff;
                      transform: translateX(-50%);
                      transition: 0.5s top;
                      &::before {
                          content: '';
                          position: absolute;
                          top: -19px;
                          left: 50%;
                          transform: translateX(-50%);
                          border: 8px solid transparent; 
                          border-bottom: 8px solid #C9C199;
                      }
                      .item{
                        position: relative;
                        text-align: left;
                        padding-left: 47px;
                        &:after {
                          content: "";
                          position: absolute;
                          top: 50%;
                          left: 32px;
                          width: 3px;
                          height: 3px;
                          border: 1px solid #999;
                          transform:rotate(-45deg)  translateY(-50%);
                          background: #999;
                        }
                      }
                      .item:hover {
                        color: #000;
                        &:after {
                          border: 1px solid #000;
                          background: #000;
                        }
                      }
                  }
                  &:hover .accountOption{
                    top: 100%;
                  }
                  &:hover .accountText{
                    color: #000;
                    background-color: #f3eac2;
                    &:after {
                        border-bottom: 1px solid #000;
                        border-left: 1px solid #000;
                    }
                  }
              }
            }
            .item:hover{
              color: #f3eac2 !important;
            }
            .services{
              position: relative;
              display: flex;
              justify-content: center;
              height: 100%;
              background: #121212;
            }
           .router-link-active, .router-link-exact-active{
              font-weight: bold;
              color: #f3eac2 !important;
              &:after {
                content: " ";
                width: 15px;
                height: 1px;
                background: #f3eac2;
                position: absolute;
                bottom: 20%;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
              }
            }
          }
        }
      }
   }
    @media screen and (max-width: 800px) {
        .headBox {
          line-height: 60px;
          font-size: 15px;
          .headMenu{
            .logoBox{
              padding-left: 2%;
              border-bottom: 0.5px solid #f3eac2;
            }
            .navBox .nav .item .accountBox .account{
                width:100px;
                height: 48px;
            } 
          }
        }
    }
</style>
