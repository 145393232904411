import axios from 'axios'
import router from '../../router'
import { Message } from 'element-ui'

axios.defaults.baseURL =  process.env.VUE_APP_API;   //全局环境变量
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 添加请求拦截器
axios.interceptors.request.use(config => {
  let baseConfig = {
    token: localStorage.getItem('Token')
  };
  config.data = {
    ...config.data,
    ...baseConfig,
  }
  return config
})
// 添加响应拦截器
axios.interceptors.response.use(response => {
  if (response.data) {
    switch (response.data.code) {
      case 11000:
        localStorage.clear()
        Message.error('账号未登录');
        router.replace('/account/login');
        break;
      case 11012:
        localStorage.clear()
        Message.error('账号token缺失');
        router.replace('/account/login');
        break;
      case 11014:
        Message.error('账户不在白名单内');
        router.replace('/');
        break;
    }
    return response.data
  } else {
    Message.error(response);
  }
}, error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 403:
        Message.error('禁止访问');
        break;
      case 404:
        Message.error('接口不存在');
        break;
      case 405:
        Message.error('不允许的HTTP请求方法');
        break;
      case 500:
        Message.error('服务器错误');
        break;
      default:
        Message.error('服务器连接异常');
        break;
    }
  } else {
    Message.error(error);
  }
  return error
})
export default axios