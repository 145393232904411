import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import account from './account'
import home from './home'
import documents from './documents'
import documentsBase from './documentsBase'
import Console from './console'
import { nanoid } from 'nanoid'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Index.vue'),
    children: [
      ...home,
      ...account,
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('@/components/Privacy.vue')
      },
    ],
  },
  {
    path: '/documents',
    component: () => import('@/components/documents/Index.vue'),
    children: [
      ...documents,
    ],
  },
  {
    path: '/documentsBase',
    component: () => import('@/components/documentsBase/Index.vue'),
    children: [
      ...documentsBase,
    ],
  },
  ...Console,
  {
    path: '*',
    name: '*',
    component: () => import('@/components/Error.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// to: Route: 即将要进入的目标 路由对象
// from: Route: 当前导航正要离开的路由
// next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。。
router.beforeEach(async (to, from, next) => {
  // 用于PU，UV 统计
  if(!Vue.$cookies.isKey('uuid')){
    let expiresTime = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1);
    Vue.$cookies.set('uuid', nanoid(),expiresTime);
  }
  

  // 用于权限拦截
  if (to.meta['isLogin']) { //是否需要登录
    if (!localStorage.getItem('Token')) { //没有token
      await window.$msgBox.alertMessageBox({
        title: '提示',
        content: '请先登录账号',
        btnText: '前往登录',
      }).then(async (data) => {
        next({
          path: '/account/login',
          query: {
            redirect: to.fullPath
          }
        }) //跳转登录页
      }).catch((val) => {});
      return
    } else {
      if (to.meta['isAuthorized']) { //查看当前路由是否需要权限
        /* 白名单机制 pass掉了 */
        // if (store.getters['accountStore/accountInfo'].isAccountWhite == 1) { //是否是白名单内(isAccountWhite:  1 白名单 2 黑名单)
          if (utils.isEmpty(store.getters['accountStore/accountInfo']) && store.getters['accountStore/accountInfo'].dataState != 2) { //是否完善资料(dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过)
            next({
              path: '/account/perfect/status'
            }); //跳转完善资料状态页
            return
          }
        // } else {
        //   await window.$msgBox.accountWhiteMessageBox({ //弹出联系邮箱弹框
        //   }).then(async (data) => {
        //     next({
        //       path: router.currentRoute.fullPath
        //     }) //跳转首页
        //   }).catch((val) => {});
        //   return
        // }
      }
    }
  }
  //用于缓存页面：配置mate:{ cacheTo: ["home/news/info"],keepAlive: true, } 
  if(from.meta){
    if (from.meta.cacheTo) {
      from.meta.cacheTo.forEach(item => {
        if (item.includes(to.name)) {
          from.meta.keepAlive = true
        } else if (!item.includes(to.name)) {
          from.meta.keepAlive = false
        }
      })
    }else{
      from.meta.keepAlive = false
    }
  }
  next();
});
export default router