let apiBase = {
  login: '/api/account/login',//登入
  loginOut: '/api/account/signOut',//登出
  register: '/api/account/register',//注册账户
  sendEmailCaptcha: '/api/account/sendEmailCaptcha',//发送邮箱验证码
  checkEmail: '/api/account/checkEmail',//检查邮箱是否注册
  getImgCaptcha: '/api/account/getImgCaptcha',//获取验证码
  checkImgCaptcha: '/api/account/checkImgCaptcha',//检查验证码
  backPassword: '/api/account/backPassword',//找回密码
  modifyPwd: '/api/account/modifyPwd',//修改密码
  modifyEmail: '/api/account/modifyEmail',//修改邮箱
  currentInfo: '/api/account/currentInfo',//获取账户信息（账户相关状态）
  getData: '/api/account/getData',//获取用户资料（用户相关资料）
  improveData: '/api/account/improveData',//完善资料
  withdrawData: '/api/account/withdrawData',//资料撤回
  

}
let AccountSeverObject = null
class AccountSever {
  constructor() {}
  login(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.login,
      data: params
    })
  }
  loginOut(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.loginOut,
      data: params
    })
  }
  register(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.register,
      data: params
    })
  }
  sendEmailCaptcha(params = {}) {
    return $http({
      url: apiBase.sendEmailCaptcha,
      method: 'post',
      data: params,
    })
  }
  checkEmail(params = {}) {
    return $http({
      url: apiBase.checkEmail,
      method: 'post',
      data: params,
    })
  }
  getImgCaptcha(params = {}) {
    return $http({
      url: apiBase.getImgCaptcha,
      method: 'post',
      data: params,
    })
  }
  checkImgCaptcha(params = {}) {
    return $http({
      url: apiBase.checkImgCaptcha,
      method: 'post',
      data: params,
    })
  }
  backPassword(params = {}) {
    return $http({
      url: apiBase.backPassword,
      method: 'post',
      data: params,
    })
  }
  modifyPwd(params = {}) {
    return $http({
      url: apiBase.modifyPwd,
      method: 'post',
      data: params,
    })
  }
  modifyEmail(params = {}) {
    return $http({
      url: apiBase.modifyEmail,
      method: 'post',
      data: params,
    })
  }
  currentInfo(params = {}) {
    return $http({
      url: apiBase.currentInfo,
      method: 'post',
      data: params,
    })
  }
  getData(params = {}) {
    return $http({
      url: apiBase.getData,
      method: 'post',
      data: params,
    })
  }
  improveData(params = {}) {
    return $http({
      url: apiBase.improveData,
      method: 'post',
      data: params,
    })
  }
  withdrawData(params = {}) {
    return $http({
      url: apiBase.withdrawData,
      method: 'post',
      data: params,
    })
  }
}

function singleAccountSever() {
  if (AccountSeverObject === null) {
    AccountSeverObject = new AccountSever()
  }
  return AccountSeverObject
}
export default singleAccountSever()