let apiBase = {
  queryMenuList: '/api/doc/queryMenuList',//获取菜单
  queryDetail: '/api/doc/queryDetail',//获取详情
  

}
let DocumentsSeverObject = null
class DocumentsSever {
  constructor() {}
  queryMenuList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryMenuList,
      data: params
    })
  }
  queryDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryDetail,
      data: params
    })
  }
}

function singleDocumentsSever() {
  if (DocumentsSeverObject === null) {
    DocumentsSeverObject = new DocumentsSever()
  }
  return DocumentsSeverObject
}
export default singleDocumentsSever()