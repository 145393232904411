<template>
    <div class="confirmMessageBox" v-show="isShowMessageBox">
      <div class="mask"></div>
      <div class="confirmMessageContent">
        <svg class="closeIcon" aria-hidden="true" @click="cancel()">
          <use xlink:href="#iconguanbi" />
        </svg>
        <h3 class="title">{{title}}</h3>
        <div class="content">{{content}}</div>
        <div class="operation">
          <div class="operationBtn confirm" @click="confirm()">确定</div>
          <div v-if="isCancelBtn" class="operationBtn" @click="cancel()">取消</div>
        </div>
      </div>
    </div>
</template>
    
  
<script>
import {singleGoodsSever} from '@/common/api-config';
import store from '../../store'
export default {
    props: {
        isShowConfimrBtn: {
            type: Boolean,
            default:true,
        },
        isCancelBtn: {
            type: Boolean,
            default:true,
        },
        title: {
          type: String,
          default: ""
        },
        content: {
          type: String,
          default: ""
        },
    },
    created(){
      
    },
    data() {
        return {
            isShowMessageBox: false,
            resolve: '',
            reject: '',
            promise: '', // 保存promise对象
            
        };
    },
    methods: {
        // 确定,将promise断定为resolve状态
        confirm: function (row) {
          this.isShowMessageBox = false;
          if(row){
            this.resolve(row);
          }else{
            this.resolve(this.activeArr);
          }
          this.remove();
        },
        // 取消,将promise断定为reject状态
        cancel: function () {
          this.isShowMessageBox = false;
          this.reject('cancel');
          this.remove();
        },
        // 弹出messageBox,并创建promise对象
        confirmMessageBox: function () {
          this.isShowMessageBox = true;
          this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
          });
          return this.promise;
        },
        remove: function () {
          setTimeout(() => {
            this.destroy();
          }, 300);
        },
        destroy: function () {
          this.$destroy();
          document.body.removeChild(this.$el);
        }
    }
};
</script>
<style lang="scss">
.confirmMessageBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 999;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    background: rgba($color: #000000, $alpha: 0.7);
  }
  .confirmMessageContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 494px;
    min-height: 262px;
    border-radius: 10px;
    background: #fff;
    .closeIcon {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .title {
      margin: 25px 0;
      min-height: 31px;
      font-size: 24px;
      font-weight: bold;
      color: rgba(18, 18, 18, 1);
      text-align: center;
    }
    .content {
      padding: 0 60px;
      font-size: 18px;
      font-weight: bold;
      color: rgba(18, 18, 18, 1);
      text-align: center;
    }
    .operation{
      display: flex;
      justify-content: space-evenly;
      .operationBtn {
        margin: 40px 0;
        width: 150px;
        height: 50px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        cursor: pointer;
        &:hover{
          background: #000;
          color: #fff;
        }
      }
      .confirm {
        background: #000;
        color: #fff;
      }
    }
  }
}
</style>