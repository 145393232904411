import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/util'
import * as filters from './filters'
import * as directives from './directives'
import Service from './common/axios-config'
import ElementUI from 'element-ui'
import './assets/css/base.css'
import 'element-ui/lib/theme-chalk/display.css';
import './assets/css/element-style/theme/index.css'
import md5 from 'js-md5';
import './icons'
import $ from 'jquery'
import MessageBox from './components/messageBox/index.js'; //封装的弹框：商品弹框
import './assets/js/iconfont.js'; // 引入阿里图标
import "babel-polyfill"; //#处理低版本兼容
import VueCookies from 'vue-cookies'
import MetaInfo from 'vue-meta-info';
import prismjs from 'prismjs'
import 'prismjs/themes/prism.css'
Vue.use(MetaInfo)
Vue.use(prismjs)
window.prismjs = Vue.prototype.prismjs = prismjs
Vue.use(VueCookies);
Vue.use(ElementUI); //注入UI
Vue.use(MessageBox); //注册自定义封装的MessageBox（自定义弹框）
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 全局指令
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})
// 全局工具
Vue.prototype.$ = $
Vue.prototype.$md5 = md5;
window.$store = store
window.$msgBox = Vue.prototype.$msgBox
window.utils = Vue.prototype.utils = utils
window.$http = Vue.prototype.$http = Service
window.configBaseURL = Vue.prototype.configBaseURL = process.env.VUE_APP_API
window.configBaseIMG = Vue.prototype.configBaseIMG = process.env.VUE_APP_IMG
window.configBaseSALT = Vue.prototype.configBaseSALT = process.env.VUE_APP_SALT

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
      document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')