let apiBase = {
  queryBannerList: '/api/banner/queryBannerList',//获取banner
  queryRecommendList:'/api/doc/queryRecommendList',//获取推荐文档列表
  queryAllianceMemberList:'/api/show/queryAllianceMemberList'//获取合作伙伴列表

}
let HomeSeverObject = null
class HomeSever {
  constructor() {}
  queryBannerList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryBannerList,
      data: params
    })
  }
  
  queryRecommendList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryRecommendList,
      data: params
    })
  }

  queryAllianceMemberList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryAllianceMemberList,
      data: params
    })
  }
}

function singleHomeSever() {
  if (HomeSeverObject === null) {
    HomeSeverObject = new HomeSever()
  }
  return HomeSeverObject
}
export default singleHomeSever()