// 全局过滤器
/*      
function trim(str)                      删除左边空格 
function parseTime(str,cFormat)         时间格式化
function formatNuber(val)               保留小数2位
function formatMoney(val)               格式货币
*/
export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a")
      return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatNuber(val) {
  let valNumber = Number(val);
  return valNumber == 0 ? "0.00" : valNumber.toFixed(2);
}

export function formatMoney(s, n) {
  if (s) {
    n = n > 0 && n <= 20 ? n : 2;
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    let l = s
      .split(".")[0]
      .split("")
      .reverse();
    let r = s.split(".")[1];
    let t = "";
    let len = l.length;
    for (let i = 0; i < len; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
    return (
      t
        .split("")
        .reverse()
        .join("") +
      "." +
      r
    );
  } else {
    return "0.00";
  }
}

export function newsType(key) {
  let label = "";
  if (key == 1) {
    label = "资讯";
  } else {
    label = "活动";
  }
  return label;
}
export function goldIdentificationState(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "认证中";
      break;
    case 2:
      label = "认证通过";
      break;
    case 3:
      label = "认证不通过";
      break;
    case 4:
      label = "认证异常";
      break;
      case 5:
      label = "认证取消";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}
export function memberApkState(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "审核中";
      break;
    case 2:
      label = "审核通过";
      break;
    case 3:
      label = "审核失败";
      break;
    case 4:
      label = "未审核";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}
export function apkReviewStateState(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "审核中";
      break;
    case 2:
      label = "审核成功";
      break;
    case 3:
      label = "审核失败";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}
export function failureReason(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "未绑定厂商账号";
      break;
    case 2:
      label = "该应用不属于您绑定的厂商账号";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}
export function appealResult(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "待审核";
      break;
    case 2:
      label = "无效反馈";
      break;
    case 3:
      label = "待处理";
      break;
    case 4:
      label = "已处理";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}

export function standardReviewStatus(key) {
  let label = "";
  switch (key) {
    case 1:
      label = "认证中";
      break;
    case 2:
      label = "认证通过";
      break;
    case 3:
      label = "认证不通过";
      break;
      case 4:
      label = "认证终止";
      break;
    default:
      label = "/";
      break;
  }
  return label;
}
//厂商任务认证_进度 必测
export function versionsTask_memberState(value) {
  return { '1': "认证中", '2': '认证通过','3': '认证未通过',}['' + value] || value;
}

//厂商任务认证_进度 警告
export function warnVersionsTask_memberState(value) {
  return { '1': "认证中", '2': '认证通过','3': '警告项未通过',}['' + value] || value;
}