let apiBase = {
  queryApkReviewList: '/api/apkReview/queryList',//获取应用list
  queryDetail: '/api/apkReview/queryDetail',//查看应用list
  update: '/api/apkReview/apply',//更新应用list
}
let ApplicationSeverObject = null
class ApplicationSever {
  constructor() {}
  queryApkReviewList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryApkReviewList,
      data: params
    })
  }
  queryDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryDetail,
      data: params
    })
  }
  update(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.update,
      data: params
    })
  }
}

function singleApplicationSever() {
  if (ApplicationSeverObject === null) {
    ApplicationSeverObject = new ApplicationSever()
  }
  return ApplicationSeverObject
}
export default singleApplicationSever()