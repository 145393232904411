let apiBase = {
  statistics: '/api/stat/add',//PU,UV
  queryBannerList: '/api/banner/queryBannerList',//获取banner
  queryNewsList: '/api/info/queryList',//获取新闻list
  queryNewsInfo: '/api/info/queryDetail',//获取新闻info
  newsSignUp: '/api/info/signUp',//报名
  newsVote: '/api/info/vote',//投票
  

}
let NewsSeverObject = null
class NewsSever {
  constructor() {}
  statistics(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.statistics,
      data: params
    })
  }
  queryBannerList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryBannerList,
      data: params
    })
  }
  queryNewsList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryNewsList,
      data: params
    })
  }
  queryNewsInfo(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryNewsInfo,
      data: params
    })
  }
  newsSignUp(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.newsSignUp,
      data: params
    })
  }
  newsVote(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.newsVote,
      data: params
    })
  }
}

function singleNewsSever() {
  if (NewsSeverObject === null) {
    NewsSeverObject = new NewsSever()
  }
  return NewsSeverObject
}
export default singleNewsSever()