<template>
  <div class="modalBox">
    <div
      role="alertdialog"
      aria-label="加入我们"
      :aria-hidden="!isShowMessageBox"
      class="modal"
      :style="{'display':isShowMessageBox?'block':'none'}"
    >
      <el-row class="modal-item">
        <div class="closeBox">
          <svg-icon @click="confirm()" svg-name="modal-close" class="close"></svg-icon>
        </div>
        <el-col :span="24" class="contentBox">
          <div>
            <el-row>
              <el-col :span="24">
                <div class="ModalTitle">欢迎与我们取得联系</div>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="24">
                <div class="first-content">
                  下载表单：
                  <a
                    class="downLink"
                    href="https://statres.itgsa.com/static/移动智能终端生态联盟申请表.docx"
                    download
                  >申请表</a>
                </div>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="24">
                <div class="modal-content indent">如您想成为金标联盟成员（生态单位、合作伙伴等），请您与我们的工作人员取得联系，您的相关咨询我们将尽快回复。如您想申请金标认证，请直接点击“联盟注册籍会员”。</div>
              </el-col>
              <el-col :span="24">
                <div class="modal-content">客服微信：itgsakf<br>邮箱：service@itgsa.com</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" :offset="0" style="text-align: center;margin-top: 10px">
                <div class="modal-foot">
                  <button class="modalButton" @click="confirm()">关 闭</button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShowMessageBox: false,
      resolve: "",
      reject: "",
      promise: "", // 保存promise对象
    };
  },
  methods: {
    // 确定,将promise断定为resolve状态
    confirm: function (row) {
      this.isShowMessageBox = false;
      if (row) {
        this.resolve(row);
      } else {
        this.resolve();
      }
      this.remove();
    },
    // 取消,将promise断定为reject状态
    cancel: function () {
      this.isShowMessageBox = false;
      this.reject("cancel");
      this.remove();
    },
    // 弹出messageBox,并创建promise对象
    accountWhiteMessageBox: function () {
      this.isShowMessageBox = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    remove: function () {
      setTimeout(() => {
        this.destroy();
      }, 300);
    },
    destroy: function () {
      this.$destroy();
      document.body.removeChild(this.$el);
    },
  },
};
</script>

<style  lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  .close {
    width: 31px;
    height: 31px;
  }
  /*display: none;*/
  .modal-item {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 846px;
    top: 18%;
    left: 50%;
    transform: translate(-50%);
    opacity: 1;
    z-index: 999;
    .contentBox {
      padding: 50px 90px 15px 90px;
    }
    @media screen and (max-width: 767px) {
      .contentBox {
        padding: 15px 20px;
      }
    }
    .closeBox {
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      padding: 11px 12px 0px 0px;
      cursor: pointer;
      z-index: 9;
    }
    .ModalTitle {
      text-align: center;
      line-height: 24px;
      font-size: 24px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
    }
    .first-content {
      margin-top: 64px;
      font-size: 18px;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      line-height: 27px;
      color: #333333;
    }
    .modal-content {
      margin-top: 40px;
      font-size: 18px;
      color: #333333;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      line-height: 27px;
    }
    .indent{
      text-indent: 2em;
    }
    .modal-foot {
      margin: 47px auto 42px auto;
      width: 51%;
      height: 50px;
      border-radius: 10px;
      cursor: pointer;
      .modalButton {
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        line-height: 50px;

        border: 2px solid rgba(58, 58, 58, 1);
        background: rgba(40, 38, 44, 1);
        color: #ffffff;
        border-radius: 5px;
        &:hover {
          color: #3a3a3a;
          background: rgba(255, 255, 255, 1);
        }
      }
    }
    @media screen and (max-width: 767px) {
      .modal-foot {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modal-item {
      width: 90%;
    }
  }
}

.downLink {
  color: #447dff;
  text-decoration: underline;
}
</style>