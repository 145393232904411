const routes = [
  {
    path: '/console',
    component: () => import('@/views/console/Index.vue'),
    children: [
      {
        path: '/console',
        name: '',
        redirect: 'goldIdentification/list'
      },
      {
        path: 'goldIdentification/list',
        name: 'list',
        meta: {
          isLogin: true,
          isAuthorized: true,
          title:'金标认证',
          menu: '/console/goldIdentification/list',
          breadcrumb:['list'],
          menuBreadcrumb: 'list',
          keepAlive: true,
        }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/goldIdentification/List.vue'),
      },
      {
        path: 'goldIdentification/form',
        name: 'goldIdentification/form',
        meta: {
          isLogin: true,
          isAuthorized: true,
          title:'认证新应用',
          menu: '/console/goldIdentification/list'
        }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/goldIdentification/Form.vue'),
      },
      {
        path: 'goldIdentification/appVersionList',
        name: 'appVersionList',
        title:'版本认证记录',
        meta: {
          isLogin: true,
          isAuthorized: true,
          menu: '/console/goldIdentification/list',
          breadcrumb:['list','appVersionList'],
          menuBreadcrumb: 'appVersionList',
          keepAlive: true,
          
        }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/goldIdentification/appVersionList.vue'),
      },
      {
        path: 'goldIdentification/attestReportList',
        name: 'attestReportList',
        title:'认证报告',
        meta: {
          isLogin: true,
          isAuthorized: true,
          menu: '/console/goldIdentification/list',
          breadcrumb:['list','appVersionList','attestReportList'],
          menuBreadcrumb: 'attestReportList',
          keepAlive: true,
        }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/goldIdentification/attestReportList.vue'),
      },
      {
        path: 'goldIdentification/reportDetails',
        name: 'reportDetails',
        title:'认证报告详情',
        meta: {
          isLogin: true,
          isAuthorized: true,
          menu: '/console/goldIdentification/list',
          breadcrumb:['list','appVersionList','attestReportList','reportDetails'],
          menuBreadcrumb: 'reportDetails',
          keepAlive: false,
        }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/goldIdentification/reportDetails.vue'),
      },
      // {应用信息
      //   path: 'application/list',
      //   name: 'application/list',
      //   meta: {
      //     isLogin: true,
      //     isAuthorized: true,
      //     menu: '/console/application/list'
      //   }, //isLogin:是否需要登录,isAuthorized:是否需求权限,menu:用于所属右侧菜单栏
      //   component: () => import('@/views/console/application/List.vue'),
      // },
      {
        path: 'cloudServices',
        name: 'cloudServices',
        meta: {
          isLogin: true,
          isAuthorized: true,
          title:'云测服务',
          menu: '/console/cloudServices'
        }, //isLogin:是否需要登录,isAuthorized:是否需求权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/cloudServices/index.vue'),
      },
      {
        path: 'allianceMembers/bind',
        name: 'allianceMembers/bind',
        meta: {
          isLogin: true,
          isAuthorized: true,
          title:'厂商账号绑定',
          menu: '/console/allianceMembers/bind'
        }, //isLogin:是否需要登录,isAuthorized:是否权限,menu:用于所属右侧菜单栏
        component: () => import('@/views/console/allianceMembers/Bind.vue'),
      },
    ]
  },
  {
    path: '/appealRecord/list',
    name: '/appealRecord/list',
    meta: {
      isLogin: true,
      isAuthorized: true,
    }, //isLogin:是否需要登录,isAuthorized:是否权限权限,menu:用于所属右侧菜单栏
    component: () => import('@/views/console/appealRecord/List.vue'),
  },
]


export default routes;