<template>
  <div id="app" class="appBox">
        <common-head id="head"></common-head>
        <router-view />
  </div>
</template>
<script>
import commonHead from "@/components/Head";
import {singleAccountSever} from "@/common/api-config";
export default {
  components: {
    commonHead
  },
};
</script>
<style lang="scss" scoped>
.appBox {
  position: absolute;
  min-width: 1280px;
  max-width: 2560px;
  width: 100%;
  height: 100%;
  font-family: Noto Sans S Chinese;
  margin: 0 auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .appBox {
    min-width: 320px;
  }
}
</style>
