let apiBase = {
  // queryIdentifyList: '/api/identify/queryList',//获取金标认证应用list
  queryIdentifyList:'/api/identifyApk/queryList',//获取金标认真应用list（新）
  queryIdentifyDetailList: '/api/identify/queryDetailList',//获取认证记录list
  identifyApply: '/api/identify/apply',//金标认证
  queryIdentifyLastDetail: '/api/identifyApk/queryDetail',//获取重新认证详情
  queryIdentifyDetail: '/api/identify/queryDetail',//获取金标认证应用详情
  queryDetailList:'/api/identify/queryDetailList',//应用版本认证记录列表
  getTypeList:'/api/appeal/getTypeList',//申诉问题类型列表
  subProblemApply:'/api/appeal/apply',//提交问题申诉
  userRetestApply:'/api/identify/userRetestApply',//版本重新提测
  queryList:'/api/appeal/queryList',//申诉记录列表
  queryDetail:'/api/appeal/queryDetail',//申诉详情
  queryApkDetail:'/api/identify/queryApkDetail',//查看报告应用信息
  queryApkDeviceList:'/api/identify/queryApkDeviceList',//测试机型列表
  queryReport:'/api/identify/queryReport',//各个标准列表
  queryFailTermDetail:'/api/identify/queryFailTermDetail',//查看未通过项详情、
  queryTermDetail:'/api/identify/queryTermDetail',//测试项详情
  queryStandardState:'/api/identify/queryStandardState',//标准状态
  queryShowStandardByApkPackage:'/api/identify/queryShowStandardByApkPackage',//显示标准(传包名)
  queryShowStandardByIdentifyId:'/api/identify/queryShowStandardByIdentifyId',//显示标准(传identifyId)
  queryNotice:'/api/show/queryNotice',//公告内容
  downloadImage:'/api/show/downloadImage',//下载图片
}
let GoldIdentificationSeverObject = null
class GoldIdentificationSever {
  constructor() {}
  queryIdentifyList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryIdentifyList,
      data: params
    })
  }
  queryIdentifyDetailList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryIdentifyDetailList,
      data: params
    })
  }
  identifyApply(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.identifyApply,
      data: params
    })
  }
  queryIdentifyLastDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryIdentifyLastDetail,
      data: params
    })
  }
  queryIdentifyDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryIdentifyDetail,
      data: params
    })
  }
  queryDetailList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryDetailList,
      data: params
    })
  }
  getTypeList(params) {
    return $http({
      method: 'get',
      url: apiBase.getTypeList,
      data: params
    })
  }
  subProblemApply(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.subProblemApply,
      data: params
    })
  }
  userRetestApply(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.userRetestApply,
      data: params
    })
  }
  queryList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryList,
      data: params
    })
  }
  queryDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryDetail,
      data: params
    })
  }
  queryApkDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryApkDetail,
      data: params
    })
  }
  queryApkDeviceList(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryApkDeviceList,
      data: params
    })
  }
  queryReport(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryReport,
      data: params
    })
  }
  queryFailTermDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryFailTermDetail,
      data: params
    })
  }
  queryTermDetail(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryTermDetail,
      data: params
    })
  }
  queryStandardState(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryStandardState,
      data: params
    })
  }
  queryNotice(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryNotice,
      data: params
    })
  }
  queryShowStandardByApkPackage(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryShowStandardByApkPackage,
      data: params
    })
  }
  queryShowStandardByIdentifyId(params = {}) {
    return $http({
      method: 'post',
      url: apiBase.queryShowStandardByIdentifyId,
      data: params
    })
  }
  downloadImage(params) {
    return $http({
      method: 'get',
      url: apiBase.downloadImage,
      data: params
    })
  }
}

function singleGoldIdentificationSever() {
  if (GoldIdentificationSeverObject === null) {
    GoldIdentificationSeverObject = new GoldIdentificationSever()
  }
  return GoldIdentificationSeverObject
}
export default singleGoldIdentificationSever()