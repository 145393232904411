const utils = {
  getTerminal() { // 判断终端是ios还是android
    var u = navigator.userAgent;
    var terminal = 'pc';
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; // android终端或者uc浏览器
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) terminal = 'android';
    if (isIOS) terminal = 'ios';
    return terminal;
  },
  getQueryString: function (name) { //截取参数
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return r[2]
    return ''
  },
  formatNumber: function (n) { //时间补0
    n = n.toString()
    return n[1] ? n : '0' + n
  },
  isEmpty: function (obj) { //是否为空
    if (typeof obj == "undefined" ) {
      return false;
    }
    if (typeof obj ==  "number" && obj === 0) {
      return false;
    }
    if (typeof obj ==  "string" && obj === '') {
      return false;
    }
    if (typeof obj ==  "boolean" && !obj) {
      return false;
    }
    if(typeof obj =='object'){
      let arr = Object.keys(obj);
      if (arr.length) {
        return true;
      } else {
        return false;
      }
    }else{
      return true;
    }
  },
  deepClone: function (obj) { //深拷贝
    return JSON.parse(JSON.stringify(obj));
  },
  throttle: function (fn, delay, atleast) { //节流
    var timer = null;
    var previous = null;
    return function () {
      var now = +new Date();
      if (!previous) previous = now;
      if (atleast && now - previous > atleast) {
        fn(this);
        previous = now;
        clearTimeout(timer);
      } else {
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn(this);
          previous = null;
        }, delay);
      }
    }
  },
  parseTime: function (time, cFormat) { //时间格式化
    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}/{m}/{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  },
  deepFlatten: function (arr) { //扁平化数组
    return [].concat(...arr.map(v => (Array.isArray(v) ? this.deepFlatten(v) : v)));
  },
  getArrFromObj: function (obj, arr) { //json 过滤 
    const rtn = [];
    const iter = obj => {
      Object.keys(obj).forEach(item => {
        arr.includes(item) && rtn.push(obj[item].authorizedRoutes)
        utils.isEmpty(obj[item].child) && iter(obj[item].child)
      });
    }
    iter(obj)
    return this.deepFlatten(rtn)
  },
  filterArr: function (arr, arrkey, value) {
    if (this.isEmpty(arr) && value) {
      let filterArr = arr.filter(item => {
        if (typeof value == 'string') {
          return value.indexOf(item[arrkey]) != -1
        }
      })
      return filterArr
    }
  },
  sort_ASCII:function (obj){
    var arr = new Array();
    var num = 0;
    for (var i in obj) {
      arr[num] = i;
      num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (var i in sortArr) {
      sortObj[sortArr[i]] = obj[sortArr[i]];
    }
    return sortObj;
  },
  jsonToParamStr:function (json){
    return Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    }).join("&");
  }
}
export default utils