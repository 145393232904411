import singleHomeSever from './home/HomeSever' //首页相关
import singleNewsSever from './home/NewsSever' //新闻相关
import singleDocumentsSever from './home/DocumentsSever' //文档相关
import singleAccountSever from './account/AccountSever' //账户相关
import singleMembersSever from './console/MembersSever' //厂商相关
import singleGoldIdentificationSever from './console/GoldIdentificationSever' //金标认证相关
import singleApplicationSever from './console/ApplicationSever' //应用信息相关
import singleServiceSever from './service/serviceSever' //应用信息相关

export {
  singleHomeSever,
  singleNewsSever,
  singleDocumentsSever,
  singleAccountSever,
  singleMembersSever,
  singleGoldIdentificationSever,
  singleApplicationSever,
  singleServiceSever,
}