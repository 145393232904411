import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store ={
  namespaced: true,
  state:{ 
    // 1层级 默认使用 titile
    // 多层级breadcrumb，menuBreadcrumb归属所处
    // meta {
    //   breadcrumb:['news','vot','detail'],
    //   menuBreadcrumb: 'detail',
    // }
    // 设置面包屑，beforeRouteEnter对应去自己的{name,path}
    "menuBreadcrumb":{},
  },
  mutations:{
    clearMenuBreadcrumb(state){
      for(let name in state.menuBreadcrumb){
        delete state.menuBreadcrumb[name];
      }
    },
    setMenuBreadcrumb(state,obj){
      state.menuBreadcrumb[obj.menu]=obj;
    },
  },
  getters:{
    getMenuBreadcrumb:(state)=>{
      return state.menuBreadcrumb
    },
    keyGetMenuBreadcrumb:(state)=>{
      return (key)=>state.menuBreadcrumb[key]
    },
  },
  actions:{//异步修改
  }
}

export default store

