const routes = [
  {
    path: '/documents',
    component: () => import('@/components/documents/Index.vue'),
  },
  {
    path: '/documents/whiteBookFile',
    redirect: '/documents/5242001394058240',
  },
  {
    path: '/documents/whiteBookFile/',
    redirect: '/documents/5242001394058240',
  },
  {
    path: '/documents/:id',
    component: () => import('@/components/documents/Index.vue'),
  },
];

export default routes;
