const routes = [
  {
    path: '/',
    meta: {
      menu: '/documentsBase/compatibleFile',
      menuName: '兼容性标准',
    }, 
    component: () => import('@/components/documentsBase/compatibleFile.vue')
  },
  {
    path: 'compatibleFile',
    name: 'compatibleFile',
    meta: {
      menu: '/documentsBase/compatibleFile',
      menuName: '兼容性标准',
    }, 
    component: () => import('@/components/documentsBase/compatibleFile.vue')
  },
  {
    path: 'stabilityFile',
    name: 'stabilityFile',
    meta: {
      menu: '/documentsBase/stabilityFile',
      menuName: '稳定性标准',
    }, 
    component: () => import('@/components/documentsBase/stabilityFile.vue')
  },
  {
    path: 'performanceFile',
    name: 'performanceFile',
    meta: {
      menu: '/documentsBase/performanceFile',
      menuName: '性能标准',
    }, 
    component: () => import('@/components/documentsBase/performanceFile.vue')
  },
  {
    path: 'powerWasteFile',
    name: 'powerWasteFile',
    meta: {
      menu: '/documentsBase/powerWasteFile',
      menuName: '功耗标准',
    }, 
    component: () => import('@/components/documentsBase/powerWasteFile.vue')
  },
  {
    path: 'safetyFile',
    name: 'safetyFile',
    meta: {
      menu: '/documentsBase/safetyFile',
      menuName: '安全标准',
    }, 
    component: () => import('@/components/documentsBase/safetyFile.vue')
  },
  {
    path: 'privacyFile',
    name: 'privacyFile',
    meta: {
      menu: '/documentsBase/privacyFile',
      menuName: '个人信息保护标准',
    }, 
    component: () => import('@/components/documentsBase/privacyFile.vue')
  },
  {
    path: 'earthquakeEarlyWarningFile',
    name: 'earthquakeEarlyWarningFile',
    meta: {
      menu: '/documentsBase/earthquakeEarlyWarningFile',
      menuName: '地震预警服务规范',
    }, 
    component: () => import('@/components/documentsBase/earthquakeEarlyWarningFile.vue')
  },
  {
    path: 'whiteBookFile',
    name: 'whiteBookFile',
    meta: {
      menu: '/documentsBase/whiteBookFile',
      menuName: 'ITGSA大屏设备应用适配白皮书',
    }, 
    component: () => import('@/components/documentsBase/whiteBookFile.vue')
  },
  {
    path: 'activityAdapterFile',
    name: 'activityAdapterFile',
    meta: {
      menu: '/documentsBase/activityAdapterFile',
      menuName: 'Activity嵌入适配指导',
    }, 
    component: () => import('@/components/documentsBase/activityAdapterFile.vue')
  },
  {
    path: 'webviewAdapterFile',
    name: 'webviewAdapterFile',
    meta: {
      menu: '/documentsBase/webviewAdapterFile',
      menuName: 'Webview适配指导',
    }, 
    component: () => import('@/components/documentsBase/webviewAdapterFile.vue')
  },
  {
    path: 'androidXOnHoverFile',
    name: 'androidXOnHoverFile',
    meta: {
      menu: '/documentsBase/androidXOnHoverFile',
      menuName: 'android T 无androidX实现悬停监听',
    }, 
    component: () => import('@/components/documentsBase/androidXOnHoverFile.vue')
  },
];

export default routes;
